
import { string } from 'yup';
import { notNullNotUndefined } from './objectUtils';
import { isStringEmail } from './stringUtils';

export function emptyStringToNull(value, originalValue) {
    if (typeof originalValue === 'string' && originalValue === '') {
        return null;
    }
    return value;
}

export function stringNotEmpty(fieldName) {
    notNullNotUndefined(fieldName);
    return string().test(
        'notEmpty',
        fieldName + ' must be at least 1 character',
        value => value !== undefined && value !== null && value.length > 0,
    )
}

export function stringMinLength(fieldName, minLength, errorMsg) {
    notNullNotUndefined(fieldName);
    notNullNotUndefined(minLength);
    return string().test(
        'notEmpty',
        errorMsg || (fieldName + ' must be at least '+minLength+' characters'),
        value => value !== undefined && value !== null && value.length >= minLength,
    )
}

export function stringIsEmail(fieldName) {
    notNullNotUndefined(fieldName)
    return string().test(
        'email',
        fieldName + ' must be an email address',
        value => value === null || isStringEmail(value),
    )
}