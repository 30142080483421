import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material"
import { Controller } from "react-hook-form"
import { isHavingValue } from "utils/objectUtils"
import { SettingsFormSelect, SettingsFormSwitch, SettingsFormTextArea, SettingsFormTextField } from "./SettingsFormLayout"

/**
 *  Components for simple use of mui with react-hook-fotm
 */




export const SfhTextField = ({control, name, errors, textFieldProps, onBeforeChange}) => {

    return (
        <Controller
        name={name}
        control={control}
        render={({field}) => (
            <SettingsFormTextField 
                error={!!errors?.[name]}
                helperText={errors?.[name]?.message}
                {...textFieldProps}
                {...field}
                onChange={e => {
                    if (onBeforeChange){
                        onBeforeChange(e)
                    }
                    field.onChange(e)
                }}
            />
        )}
        />
    )

}

export const SfhTextArea = ({control, name, errors, textAreaProps}) => {

    return (
        <Controller
        name={name}
        control={control}
        render={({field}) => (
            <SettingsFormTextArea
                error={!!errors?.[name]}
                helperText={errors?.[name]?.message}
                {...textAreaProps}
                {...field}
            />
        )}
        />
    )

}

export const SfhSelect = ({control, name, errors, children, selectProps}) => {

    //does not display error message

    return (
        <Controller
            name={name}
            control={control}
            render={({field}) => (
                <SettingsFormSelect
                    error={!!errors?.[name]}
                    {...selectProps}
                    {...field}
                >
                    {children}
                </SettingsFormSelect>
            )}
        />
    )

}

export const SfhCheckbox = ({control, name, errors, label, checkboxProps}) => {

    const hasError = isHavingValue(!!errors?.[name])

    return (
        <Controller
            name={name}
            control={control}
            render={({field}) => (
                <FormControl error={hasError}>
                    <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            size="medium"
                            {...checkboxProps}
                            {...field}
                        />
                    }
                    label={label}
                    />
                    {hasError && (
                        <FormHelperText>{errors[name]?.message}</FormHelperText>
                    )}
                </FormControl>
            )}
        />
    )

}

export const SfhSwitch = ({control, name, errors, label, switchProps}) => {

    const hasError = isHavingValue(!!errors?.[name])

    return (
        <Controller
            name={name}
            control={control}
            render={({field:{onChange,value}}) => (
                <FormControl error={hasError}>
                    <FormControlLabel
                    control={
                        <SettingsFormSwitch
                            {...switchProps}
                            onChange={(e) => onChange(e.target.checked)}
                            checked={value}
                        />
                    }
                    label={label}
                    />
                    {hasError && (
                        <FormHelperText>{errors[name]?.message}</FormHelperText>
                    )}
                </FormControl>
            )}
        />
    )

}


