import makeStyles from '@mui/styles/makeStyles';
import { ChipStyled } from "modules/picasso-ui/present/chip/ChipStyled"
import { useTranslation } from "next-i18next"

const useStyles = makeStyles(()=>({
    done: {
        backgroundColor: '#c0f9d5',
        color: 'rgb(56 131 83 / 87%)',
    },
}))

export const ItemLabelStatusChip = ({type}) => {

    const classes = useStyles()

    const { t } = useTranslation(['vsprio'])

    return (
        <ChipStyled classes={{chip: classes[type]}} label={t(`vsprio:backlog.item.labels.status.${type}.title`)}/>
    )

}