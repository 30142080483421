import ImageUploading, { ImageListType } from "react-images-uploading";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Box, Button, ButtonProps, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { AssetsView } from "./AssetsView";
import { FunctionComponent, ReactNode } from "react";

const useStyles = makeStyles(()=>({
    errors: {
        color: 'red',
    },
}))

export interface AssetsUploadBarProps {
  showRemoveAllImagesButton?: boolean
  onChange: (imageList: ImageListType) => void
  assets?: any[]
  hideRemove?: boolean
  buttonProps?: ButtonProps
  maxNumber?: number
  renderTooltipContent?: (maxNumber?: number) => ReactNode
  acceptType?: string[]
}

// Supports only Images
export const AssetsUploadBar: FunctionComponent<AssetsUploadBarProps> = ({showRemoveAllImagesButton, onChange, assets, hideRemove, buttonProps, maxNumber, renderTooltipContent, acceptType}) => {
    
    const classes = useStyles()

    maxNumber = maxNumber || 5
    const maxFileSiteB = 5000000

    const handleChange = (imageList, addUpdateIndex) => {
      if (onChange) {
        onChange(imageList)
      }

    };

    return (
        <ImageUploading
        multiple
        value={assets}
        onChange={handleChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        maxFileSize={maxFileSiteB}
        acceptType={acceptType||['jpeg','jpg', 'gif', 'png']}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => (
          <Box display="inline-block">
                <Tooltip title={renderTooltipContent ? renderTooltipContent(maxNumber) : 'Upload images (max. '+maxNumber+')'}>
                  <Button
                    style={buttonProps ? null : {paddingRight: '6px', paddingLeft: '6px', minWidth: '10px'}}
                    onClick={onImageUpload}
                    //@ts-ignore Must use module augmentation to add colors https://mui.com/material-ui/customization/palette/#adding-new-colors
                    color={buttonProps?.color || "greyDark"}
                    variant={buttonProps?.variant || "outlined"}
                    {...dragProps}
                    {...buttonProps}
                    disabled={buttonProps?.disabled || imageList.length >= maxNumber}
                  >
                      {buttonProps?.children || <AttachFileIcon />}
                  </Button>
                </Tooltip>
                {showRemoveAllImagesButton === true && imageList.length > 0 && (<button onClick={onImageRemoveAll}>Remove all images</button>)}
                {hideRemove !== true && imageList.length > 0 && (
                    <AssetsView assets={imageList.map((i, idx)=>({assetId: idx.toString(), url: i.data_url}))} onImageRemove={(assetId: string)=>onImageRemove(parseInt(assetId))} />
                )}
                {
                    errors && <Box className={classes.errors} pt={1} pb={1}>
                    {errors.acceptType && <span>Your selected file type is not allowed</span>}
                    {errors.maxFileSize && <span>Maximum size per file is {maxFileSiteB/1000/1000} MB</span>}
                    {errors.maxNumber && <span>Maximum {maxNumber} more images</span>}
                    </Box>
                }
            </Box>
            )}
      </ImageUploading>
    )

}