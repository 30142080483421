import { useMediaQuery, useTheme } from "@mui/material";
import { SimpleDialog, useDialogOpen } from "components/dialog/SimpleDialog";
import { FloatingButton } from "../form/FloatingButton";

export const BreakpointFallbackFloatingButton = ({children, defaultWrapper, down, btnText, btnIcon}) => {

    const theme = useTheme()

    const [open, openDialog, closeDialog] = useDialogOpen()

    const fallbackVisible = useMediaQuery(theme.breakpoints.down(down));

    const renderOpenBtn = () => {
        return (
            <FloatingButton color="primary" variant="extended" onClick={openDialog} size="medium">
                {btnIcon && <>{btnIcon}&nbsp;</>}{btnText}
            </FloatingButton>
        )
    }

    return (
        <>
            {fallbackVisible ? 
                <>
                    <SimpleDialog open={open} onClose={closeDialog} fullWidth={true}>
                        {children}
                    </SimpleDialog>
                    {renderOpenBtn()}
                </>
                :
                <>{defaultWrapper ? defaultWrapper(children) : children}</>
            }
        </>
    )

}