import { Box, Button, Typography } from "@mui/material";
import ProgressButton from "modules/picasso-ui/form/ProgressButton"
import { SfhTextArea, SfhTextField } from "modules/picasso-ui/form/settingsForm/SettingsFormHookForm"
import { submitFeedbackItem, uploadItemImageFromBase64 } from "modules/plinzip/api/backlogApi"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { entityId } from "utils/apiUtils"
import { stringMinLength } from "utils/yupUtils"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ItemTypeSelect } from "modules/plinzip/component/backlogItem/ItemTypeSelect"
import { AssetsUploadBar } from "modules/picasso-ui/form/assets/AssetsUploadBar"
import alerts from "modules/yoio/state/alerts"
import { isNullOrUndefined, orElse } from "utils/objectUtils"
import { useTranslation } from "next-i18next"
import { VerticalAlign } from "modules/picasso-ui/layout/VerticalAlign"
import { ThankYouView } from "modules/picasso-modules/feedback/FeedbackComponents";

export const FeedbackSendWidget = ({backlogId, onFeedbackSubmitted, submitButtonProps, showAttachmentsBar, showTitle, title, enableThankYouPhase, onCancel, descriptionRequired, origin, showType}) => {

    const { t } = useTranslation(['vsprio', 'common'])

    const [submitting, setSubmitting] = useState(false)

    const [attachments, setAttachments] = useState([])

    const [uploadProgresses, setUploadProgresses] = useState(null) //einzelne uploads

    const [uploadProgress, setUploadProgress] = useState(null)

    const [phase, setPhase] = useState('input') //'input' 'thankYou'

    showTitle = orElse(showTitle, true)
    descriptionRequired = orElse(descriptionRequired, true)

    const createSchema = () => {
        const shape = {
            name: stringMinLength('Title', 3),
        }

        if (descriptionRequired) {
            shape.description = stringMinLength('Details', 10)
        }
        
        return yup.object().shape(shape);
    }

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(createSchema(descriptionRequired)),
        defaultValues: {
            name: '',
            description: '',
            type: 'idea',
        }
    })
    
    const onUploadProgress = (event, progressId) => {
        if (isNullOrUndefined(event)) {
            console.error('upload progress error. not showing progress. event: ' + event)
            return;
        }
        if (isNullOrUndefined(progressId)) {
            console.error('upload progress error. not showing progress. progressId: ' + progressId)
            return;
        }

        setUploadProgresses(curr=>{
            let updated = curr ? curr : []

            let el = updated.find(el => el.progressId === progressId)
            if (!el) {
                updated.push({
                    loaded: event.loaded,
                    total: event.total,
                    progressId,
                })
            } else {
                el.loaded = event.loaded
            }

            return updated
        })

    }

    useEffect(()=>{
        setUploadProgress(calcUploadProgress())
    }, [uploadProgresses])

    const calcUploadProgress = () => {
        //console.debug('calcUploadProgressA', uploadProgresses, attachments)
        //console.debug('calcUploadProgressB', submitting, uploadProgresses?.length+'_', attachments?.length+'_')

        if (!uploadProgresses) {
            //console.debug('test fail 1');
            return null;
        }
        if (uploadProgresses.length < attachments.length) {
            //console.debug('test fail 2');
            return 0;
        }

        let total = 0;
        let loaded = 0;
        uploadProgresses.forEach(p => {
            total = total + p.total
            loaded = loaded + p.loaded
        });

        let percent = Math.round((100 * loaded) / total)

       // console.log('totals', loaded, total, percent)

        return percent
    }

    const onSubmit = (data) => {
        setSubmitting(true)

        const itemId = entityId()

        if (showAttachmentsBar === true) {
            let imageUploads = attachments ? attachments.map((a)=>{
                const progressId = entityId();
                return uploadItemImageFromBase64(backlogId, itemId, a.data_url, (event)=>onUploadProgress(event, progressId))
            })
            : [] // or no promises
        
            if (imageUploads?.length > 0) {
                Promise.all(imageUploads).then((values) => {
                    setUploadProgresses(null)
                    const assetIds = values.map(v=>v.data.assetId)
                    return submitItemItself(data, itemId, assetIds) //WITH IMAGES
                }).catch((e)=> {
                    alerts.error('error uploading attachments. please retry.')
                    console.log(e)
                    setSubmitting(false)
                    setUploadProgresses(null)
                })
                return; //VERY IMPORTANT
            }
        }
            
        submitItemItself(data, itemId)// WITHOUT IMAGES
    }

    const handleFeedbackSubmitted = () => {
        if (enableThankYouPhase) {
            setPhase('thankYou')
        }
        if (onFeedbackSubmitted) {
            onFeedbackSubmitted()
        }
    }

    const submitItemItself = (data, itemId, assetIds) => {
        return submitFeedbackItem(
            {
                backlogId, 
                data: {
                    itemId,
                    name: data.name,
                    description: data.description,
                    type: data.type,
                    assetIds,
                    origin
                }
            }
        )
        .then(()=> {
            reset()
            setAttachments([])
            setUploadProgresses(null)
            setSubmitting(false)
            handleFeedbackSubmitted()
        })
        .catch(()=> {
            setSubmitting(false)
            setUploadProgresses(null)
        })
    }

    const handleAttachmentsChange = (attachments) => {
        setAttachments(attachments)
    }

    const renderCancelBtn = () => {
        return <Button onClick={onCancel}>{t('common:action.cancel')}</Button>
    }

    const renderSubmitBtn = () => {
        return (<>
            <ProgressButton 
                color="primary" 
                variant="contained" 
                type="submit"
                loading={submitting}
                {...submitButtonProps}
            >
                    {t('vsprio:portal.feedback.submit.submitBtnText')}
            </ProgressButton>
        </>
        )
    }

    const handleThankYouOkClick = () => {
        setPhase('input')
    }

    return <>
        {phase === 'input' && 
            <form onSubmit={handleSubmit(onSubmit)}>
                
                {showTitle === true && (
                    <Box mb={3}>
                        <Typography variant="h3">{title || t('vsprio:portal.feedback.submit.submitFormTitle')}</Typography>
                    </Box>
                )}

                {showType === true && 
                    <Box mb={3}>
                        <Controller
                            name="type"
                            control={control}
                            render={({field}) => (
                                <ItemTypeSelect 
                                    error={!!errors?.["type"]}
                                    {...field}
                                    size="small"
                                />
                            )}
                        />
                    </Box>
                }

                <Box mb={3}>
                    <SfhTextField
                        name="name"
                        control={control}
                        errors={errors}
                        textFieldProps={{
                            label: t('vsprio:portal.feedback.submit.title.helperText'),
                            size: 'small',
                            autoComplete: 'off',
                            inputProps: {
                                maxLength: '150'
                            },
                        }}
                    />
                </Box>

                <Box mb={3}>
                    <SfhTextArea
                        name="description"
                        control={control}
                        errors={errors}
                        textAreaProps={{
                            label: t('vsprio:portal.feedback.submit.description.helperText'),
                            rows: 4,
                            inputProps: {
                                maxLength: '1000'
                            },
                        }}
                    />
                </Box>

                {showAttachmentsBar === true && (
                    <>
                        <Box pb={1} display="flex" justifyContent="space-between">
                            <AssetsUploadBar 
                                assets={attachments}
                                onChange={handleAttachmentsChange}
                                />

                            {attachments.length === 0 && (
                                <VerticalAlign>
                                    {onCancel &&
                                        renderCancelBtn()
                                    }
                                    {renderSubmitBtn()}
                                </VerticalAlign>
                            )}
                        </Box>

                        {(attachments.length >= 1) && (
                            <Box align="right" display="flex" alignItems="center">
                                {onCancel &&
                                    renderCancelBtn()
                                }
                                {renderSubmitBtn()}
                            </Box>
                        )}
                    </>
                )}

                {(showAttachmentsBar === false) && (
                    <VerticalAlign style={{
                        justifyContent: 'flex-end',
                    }}>
                        {onCancel &&
                            renderCancelBtn()
                        }
                        {renderSubmitBtn()}
                    </VerticalAlign>
                )}

            
            </form>
        }
        {phase === 'thankYou' && (
            <ThankYouView 
                onDoneClick={handleThankYouOkClick}
                translations={{
                    title: t('vsprio:portal.feedback.submit.thankYou.title'),
                    description: t('vsprio:portal.feedback.submit.thankYou.description'),
                }}
            />
        )}
    </>
}
