import { Box, Button, ButtonProps, css, Typography } from "@mui/material"
import { MultilineTypography } from "modules/picasso-ui/present/MultilineText"
import Image from "next/image"
import { FunctionComponent } from "react"
import { isHavingValue } from "utils/objectUtils"

export interface ThankYouViewTranslations {
    title?: string
    description?: string
}

export interface ThankYouViewProps {
    onDoneClick?: () => void
    translations?: ThankYouViewTranslations
    size?: 'standard' | 'small'
    doneButtonProps?: ButtonProps
}

export const ThankYouView: FunctionComponent<ThankYouViewProps> = ({onDoneClick, translations, size, doneButtonProps}) => {

    size = size || 'standard'

    const style = css({
        display: 'flex',
        flexDirection: size === 'small' ? 'row':'column',
        gap: size === 'small' ? '12px':null,
    })

    const renderDoneButton = () => {
        if (!onDoneClick) return null
        return <div style={{textAlign: size==='small'?'right':'center'}}><Button size="medium" variant="outlined" onClick={onDoneClick} {...doneButtonProps}>OK</Button></div>
    }

    return (
        <Box textAlign={size === 'standard'? 'center':null} pt={size === 'standard'?3:null}>
            <div css={style}>
                <Box mb={1}>
                    <Image src="/vsprio/embed/high-five.svg" width="95px" height="95px" />
                </Box>   
                <Box>
                    <Typography mb={1} sx={{fontSize: size === 'standard' ? '22px': '16px', fontWeight: '600',}}>{translations?.title || 'Thank you.'}</Typography>
                    <Box mb={size === 'standard' ? 4:2} color="text.secondary">
                        {/** @ts-ignore */}
                        <MultilineTypography>{isHavingValue(translations.description) ? translations.description : 'We received your feedback\\nand will review it.'}</MultilineTypography>
                    </Box>
                    {size=== 'small' && renderDoneButton()}
                </Box>
            </div>
            {size=== 'standard' && renderDoneButton()}
        </Box>
    )
    
}