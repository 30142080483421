import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(()=>({
    outer: {
        position: 'relative',
    },
    inner: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 4,
        cursor: 'pointer',
    },
    protectedArea: {
        zIndex: 3,
    }
}))

export const InterceptClickBoundary = ({children, onClick}) => {

    const classes = useStyles()

    const handleClick = (e) => {
        e.stopPropagation()
        e.preventDefault()
        onClick(e)
    }

    return (
        <div className={classes.outer}>
            <div className={classes.protectedArea}>
                {children}
            </div>
            <div className={classes.inner} onClick={handleClick}>

            </div>
        </div>
    )

}