import { MenuItem } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { SettingsFormSelect } from "modules/picasso-ui/form/settingsForm/SettingsFormLayout"
import { useTranslation } from "next-i18next"
import { forwardRef } from "react"

const useStyles = makeStyles(()=>({
    menuItem: {
        '& $bullet_idea': {
            fontSize: '26px',
            lineHeight: '26px',
        },
        '& $bullet_feature': {
            fontSize: '26px',
            lineHeight: '26px',
        },
        '& $bullet_bug': {
            fontSize: '26px',
            lineHeight: '26px',
        }
    },
    bullet_idea: {
        color: '#23d163',
        display: 'inline-block',
        marginRight: '4px',
    },
    bullet_feature: {
        color: '#89b2f5',
        display: 'inline-block',
        marginRight: '4px',
    },
    bullet_bug: {
        color: '#ae95db',
        display: 'inline-block',
        marginRight: '4px',
    }
}))

export const ItemTypeSelect = forwardRef((props, ref) => {

    const classes = useStyles()

    const { t } = useTranslation(['vsprio'])

    const renderTypeOptions = () => {
        return ['idea', 'bug', 'feature'].map((type, idx) => {
            return <MenuItem key={idx} value={type} className={classes.menuItem}><span className={classes[`bullet_${type}`]}>&#8226;</span> {t(`vsprio:backlog.item.labels.type.${type}.title`)}</MenuItem>
        })
    }

    return (
        <SettingsFormSelect {...props} ref={ref}>
            {renderTypeOptions()}
        </SettingsFormSelect>
    )
    

})