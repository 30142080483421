import { Typography, TypographyProps } from "@mui/material";
import { ElementType } from "react";

const styleDef = {
   root: {
        overflowWrap: 'anywhere',
   }
}

export const TypographyBreakText = ({className, component, sx, ...other}:(TypographyProps&{component?: ElementType})) => {
    return <Typography className={className} {...other} sx={[styleDef.root, ...(Array.isArray(sx) ? sx : [sx]),]} component={component} />
}