import { FunctionComponent, ReactNode } from "react";
import { isFunction } from "utils/objectUtils";
const { useTheme, useMediaQuery } = require("@mui/material");
const { SimpleDialog } = require("components/dialog/SimpleDialog");

export interface BreakpointFallbackDialogProps {
    down: string
    children: ReactNode | ((isFallback: boolean)=>ReactNode)
    defaultWrapper: (children: ReactNode)=>ReactNode
    onClose: ()=>void
}

export const BreakpointFallbackDialog: FunctionComponent<BreakpointFallbackDialogProps> = ({children, defaultWrapper, onClose, down}) => {

    const theme = useTheme()
    const dialogOpen = useMediaQuery(theme.breakpoints.down(down));

   
    const renderedChildren = isFunction(children) 
        //@ts-ignore because it is checked with isFunction
        ? children(dialogOpen) 
        : children

    return (
        <>
            {dialogOpen ? 
                <SimpleDialog open={dialogOpen} onClose={onClose} fullWidth={true}>
                    {renderedChildren}
                </SimpleDialog>
                :
                <>{defaultWrapper ? defaultWrapper(renderedChildren) : renderedChildren}</>
            }
        </>
    )

}