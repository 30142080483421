import makeStyles from '@mui/styles/makeStyles';
import { ChipStyled } from "modules/picasso-ui/present/chip/ChipStyled"
import { useTranslation } from "next-i18next"

const useStyles = makeStyles(()=>({
    feature: {
        backgroundColor: '#dae8ff',
        color: 'rgb(73 114 178 / 87%)',
    },
    idea: {
        backgroundColor: '#c0f9d5',
        color: 'rgb(56 131 83 / 87%)',
    },
    bug: {
        backgroundColor: '#f1e9ff',
        color: 'rgb(164 120 190 / 87%)',
    },
}))

export const ItemLabelTypeChip = ({type}) => {

    const classes = useStyles()

    const { t } = useTranslation(['vsprio'])

    return (
        <ChipStyled classes={{chip: classes[type]}} label={t(`vsprio:backlog.item.labels.type.${type}.title`)}/>
    )

}