import { Box, Container, Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { AppBarPush } from "components/layout/positioning/AppBarPush";
import { useIsMounted } from "modules/picasso-ui/form/useIsMounted";
import { CardNice3 } from "modules/picasso-ui/present/CardNice3";
import { AppBarLandingSaas } from "modules/yoio/component/AppBarLandingSaas";
import { useRef, useState } from "react";
import { useResolved, useResolvedItem } from "utils/useResolved";
import { getPortal, getPortalItems, itemUnclearVoteCreateMy, itemUnclearVoteDeleteMy, itemVoteCreate, itemVoteDelete } from "../../api/backlogApi";
import { FeedbackItemCardContent, FeedbackListView } from "./FeedbackListView";
import { FeedbackSendWidget } from "./FeedbackSendWidget";
import alerts from 'modules/yoio/state/alerts';
import { useApp } from "modules/yoio/useApp";
import Image from "next/image";
import { BreakpointFallbackFloatingButton } from "modules/picasso-ui/responsive/BreakpointFallbackFloatingButton";
import { BreakpointFallbackDialog } from "modules/picasso-ui/responsive/BreakpointFallbackDialog";
import AddIcon from '@mui/icons-material/Add';
import { orElse } from "utils/objectUtils";

const sideBySideFrom = 'md'
const sideBySideFromDown = 'sm'

const useStyles = makeStyles((theme)=>({
    container: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(10),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: 0,
        }
    },
    gridContainer: {
        flexDirection: 'column-reverse',
        [theme.breakpoints.up(sideBySideFrom)]: {
            flexDirection: 'row',
        },
    },
    feedbackListGridItem: {
        '&+$feedbackListGridItem': {
            marginBottom: theme.spacing(4),
        },
        [theme.breakpoints.up(sideBySideFrom)]: {
            '&:not(:last-child)': {
                paddingRight: theme.spacing(8) 
            },
            '&+$feedbackListGridItem': {
                marginTop: 0,
                marginBottom: 0,
            },
        }
    },
    rightGridItemContent: {
        [theme.breakpoints.up(sideBySideFrom)]: {
            position: 'sticky',
            top: '100px',
        }
    },
    portalName: {
        fontSize: '16px',
        fontWeight: '500',
        color: '#fff',
        //color: theme.palette.text.primary,
        whiteSpace: 'nowrap'
    },
    portalDescription: {
        fontSize: '14px',
        fontWeight: '400',
        //color: 'rgba(255, 255, 255, 0.72)',
    },
    portalAppBarPartial: {
        'overflow-y': 'hidden',
        maxHeight: '500px', /* approximate max height */
        'transition-property': 'all',
        'transition-duration': '.2s',
        'transition-timing-function': 'cubic-bezier(0, 1, 0.5, 1)',
    },
    portalAppBarPartialHidden: {
        maxHeight: '0',
    },
    portalAppBar: {
        //boxShadow: 'none',
        borderBottom: '1px solid #dedede !important',
        //boxShadow: 'rgb(88 102 126 / 8%) 0px 4px 24px, rgb(88 102 126 / 12%) 0px 1px 2px !important',

        background: 'rgb(102 160 255)',
        background: 'linear-gradient(90deg, rgb(102 160 255) 0%, rgb(62 135 255) 100%)',

        boxShadow: 'rgb(88 102 126 / 8%) 0px 4px 24px, rgb(88 102 126 / 12%) 0px 2px 2px !important',

        //background: '#1e3c72', 
        //background: '-webkit-linear-gradient(to right, #1e3c72, #2a5298)', 
        //background: 'linear-gradient(to right, #365ea4, #27457d)',
        //background: 'rgb(0,71,190)',
        //background: 'linear-gradient(90deg, rgba(0,71,190,1) 0%, rgba(0,63,168,1) 100%)',
    },
    primaryBtn: {
/*         '&,&:not([disabled])': {
            background: '#365ea4',
        } */
    },
}))

export const FeedbackPortalView = ({backlogId, backlogEac, embedded, customName, showAppBar, noContainer, sendWidgetEnabled, showVoteCount, showUnclearFlag, showScore, listItemProps, variant}) => {

    const classes = useStyles()

    const portalResolved = useResolvedItem(()=>getPortal(backlogId))

    const [items, refresh, remove, loading] = useResolved(() => getPortalItems(backlogId).then((data) => data.data), 'itemId');

    const [silentReload, setSilentReload] = useState(false)

    const [itemSelected, setItemSelected] = useState(null)

    const itemContent = useRef(null);

    const isMounted = useIsMounted()

    const handleFeedbackSubmitted = () => {
        alerts.success('Your feedback will be reviewed.')
         /** 
        setSilentReload(true)
        setTimeout(()=> {
            if (isMounted()) {
                refresh({silent: true})
                .then(()=> setSilentReload(false))
            }
        }, 500)
        */
    }

    const handleItemClicked = (item) => {
        setItemSelected(current => {
          if (current?.itemId === item.itemId) {
            return null
          }
          return item
        });
    }

    const handleClickAwayFromListAndItemContent = (e) => {
        //click away from scoring widget and outside of drawer
        if (itemContent.current && !itemContent.current.contains(e.target)) {
          setItemSelected(null);
        }
      }

/*     const renderAppBar = () =>  
        <> 
            <AppBarLandingSaas 
                responsive={true}
                >
            </AppBarLandingSaas>
            <AppBarPush xs={'140px'}/> 
        </> */

    const handleCloseItemClick = () => {
        setItemSelected(null);
    }

    const handleItemVoteCreate = (item) => {
        itemVoteCreate(backlogId, item.itemId)
        .then(()=> {
            //refresh({silent: true})
        })
    }

    const handleItemVoteDelete = (item) => {
        itemVoteDelete(backlogId, item.itemId)
        .then(()=> {
            //refresh({silent: true})
        })
    }

    const feedbackSubmitOpen = true

    const renderInner = () => {
        return (
            <>
                <Box>
                    <Grid container className={classes.gridContainer}>
                        <Grid item xs={12} className={classes.feedbackListGridItem} {...{[sideBySideFrom]: 8}}>
                            <FeedbackListView 
                                items={items} 
                                loading={loading} 
                                refreshing={silentReload} 
                                showListTopAndBottomDividers={true} 
                                showVoteCount={showVoteCount}
                                showUnclearFlag={showUnclearFlag}
                                showScore={showScore}
                                onItemClick={handleItemClicked}
                                onVoteCreate={handleItemVoteCreate}
                                onVoteDelete={handleItemVoteDelete}
                                noItemPadding={true}
                                backlogEac={backlogEac}
                                listItemProps={listItemProps}
                                variant={variant}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.feedbackListGridItem} {...{[sideBySideFrom]: 4}} >

                            {itemSelected &&
                                <Box className={classes.rightGridItemContent}>
                                    <BreakpointFallbackDialog
                                        defaultWrapper={c=><CardNice3 fullWidthStyleOnXs={true} onCloseClick={handleCloseItemClick}>{c}</CardNice3>}
                                        onClose={()=>setItemSelected(null)}
                                        down={sideBySideFromDown}
                                    >
                                        <FeedbackItemCardContent
                                            item={itemSelected}
                                            backlogId={backlogId}
                                            key={itemSelected.itemId}
                                            backlogEac={backlogEac}
                                        />
                                    </BreakpointFallbackDialog>
                                </Box>
                            }
                            {sendWidgetEnabled && !itemSelected && (
                                <>
                                    {feedbackSubmitOpen && (

                                        <BreakpointFallbackFloatingButton 
                                            btnText={'Feedback'}
                                            btnIcon={<AddIcon />}
                                            down={sideBySideFromDown}
                                            defaultWrapper={c=>(
                                                <Box style={{position: 'sticky', top: '100px'}}>
                                                    <CardNice3 fullWidthStyleOnXs={true}>
                                                        {c}
                                                    </CardNice3>
                                                </Box>
                                            )}
                                        >
                                            <FeedbackSendWidget 
                                                backlogId={backlogId} 
                                                onFeedbackSubmitted={handleFeedbackSubmitted}
                                                submitButtonProps={{
                                                    className: classes.primaryBtn
                                                }}
                                                showAttachmentsBar={true}
                                                showType={true}
                                            />
                                        </BreakpointFallbackFloatingButton>

                                    )}
                                </>
                            )}

                        </Grid>
                    </Grid>
                    {/* </ClickAwayListener> */}
                </Box>
            </>
        )
    }

    return (
        <>  
            {/* {renderAppBar()} */}
            {showAppBar && 
                <PortalAppBar 
                    portal={portalResolved.item} 
                    appBarPosition={embedded === true ? 'static' : null} 
                    customName={customName} 
                    appBarPushEnabled={embedded !== true}
                    />
            }
                {noContainer ? 
                    renderInner()
                    :
                    <Container maxWidth="lg" className={classes.container} style={{paddingTop: embedded ? '24px': null}}>
                        {renderInner()}
                    </Container>
                }

            </>
    )
    
}

export const PortalAppBar = ({portal, appBarPosition, customName, appBarPushEnabled, slim}) => {

    const classes = useStyles()

    //const { scrollPosition } = useScrollPosition() 

    const narrow = true //scrollPosition > 20

    const { app } = useApp()

    appBarPushEnabled = orElse(appBarPushEnabled, true)

    return (
        <>
            <Box>
                <AppBarLandingSaas 
                    className={classes.portalAppBar} 
                    position={appBarPosition}
                    hideAppLogo={true}
                    slim={slim}
                    contentLeft={
                        <Box pt={slim? 0 : (narrow ? 1.5: 2)} pb={slim ? 0 : (narrow? 1.5: 2)}>
                            <Box display="flex" alignItems="center">
                                <Box mr={2} pt={'2px'}>
                                    <Image src={app.logoSrc} height={25} width={25} priority={true} />
                                </Box>
                                <Box pb={'1px'}>
                                    <Typography component="h1" className={classes.portalName}>{customName || portal?.name}</Typography>
                                </Box>
                            </Box>
                            <Box className={narrow ? [classes.portalAppBarPartialHidden,classes.portalAppBarPartial].join(' ') : [classes.portalAppBarPartial].join(' ')}>
                            <Box mt={1}>
                                    <Typography component="h1" className={classes.portalDescription}>
                                    Suggest your ideas and let us know how we can improve.    
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    }
                />
                {appBarPushEnabled && (
                    <AppBarPush xs={'90px'} md={'50px'}/> 
                )}
            </Box>
        </>
    )

}