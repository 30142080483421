import { Box, Divider, LinearProgress, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { EmptyState } from "modules/picasso-ui/present/emptyState/EmptyState"
import { LoadingBorder } from "components/layout/loadingState/LoadingBorder"
import { SortButton } from "modules/picasso-ui/present/list/SortButton"
import { MultilineText } from "modules/picasso-ui/present/MultilineText"
import { ItemLabelTypeChip } from "modules/plinzip/component/ItemLabelTypeChip"
import { useTranslation } from "next-i18next"
import { useMemo, useState } from "react"
import { dynamicSort } from "utils/arrayUtils"
import { isHavingValue, isObjectEmpty } from "utils/objectUtils"
import { FeedbackCommentWidget } from "./FeedbackCommentWidget"
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { ItemLabelStatusChip } from "modules/plinzip/component/ItemLabelStatusChip"
import { AssetsView } from "modules/picasso-ui/form/assets/AssetsView"
import { joinClasses } from "utils/muiUtils"
import CheckIcon from '@mui/icons-material/Check';
import { useWallDialog } from "components/dialog/confirm/WallDialog"
import { hasPermissionInEac } from "modules/react-auth/component/border/PermissionEntityBorder"
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { TypographyBreakText } from "modules/picasso-ui/present/text/TypographyBreakText"
import { SearchInput } from "modules/picasso-ui/dataviews/SearchInput"
import { VerticalAlign } from "modules/picasso-ui/layout/VerticalAlign"
import { TypographyEllipsis } from "modules/picasso-ui/present/text/TypographyEllipsis"
import { ItemNameEditInline, ItemUnclearVoteButton } from "../room/common/RoomComponents"

const useStyles = makeStyles((theme)=>({
    root: {
    },
    entryBoxWrapper: {
        marginBottom: theme.spacing(4),
    },
    entryBoxWrapperNarrow: {
        marginBottom: theme.spacing(0),
        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.divider}`, 
        },
        '& $name': {
            fontSize: '14px',
        },
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '& $entryBoxWhenItemClickable': {
            borderRadius: '0px',
        },
    },
    entryBox: {
        
    },
    entryBoxWhenItemClickable: {
        borderRadius: '6px',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        [theme.breakpoints.up('sm')]: {
            '&:hover': {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                marginLeft: -theme.spacing(2),
                marginRight: -theme.spacing(2),
            }
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        }
    },
    searchCont: {
        width: '200px',
    },
    itemsWrapper: {
        [theme.breakpoints.down('sm')]: {
            //marginLeft: -theme.spacing(2),
            //marginRight: -theme.spacing(2),
        }
    },
    name: {
        fontWeight: '500',
        fontSize: '16px',
    },
    feedbackEntryDescripton: {
        display: 'box',
        boxOrient: 'vertical',  
        overflow: 'hidden',
        minHeight: theme.spacing(2),
        lineClamp: 2,
        //fontSize: '14px',
    },
    itemScore: {
        border: theme.customProps.outlinedCardStyle.border,
        padding: theme.spacing(1),
        borderRadius: '5px',
        width: '50px',
        textAlign: 'center',
        color: '#3e4960',
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: theme.typography.fontFamily,
    },
    voteCountBox: {
        border: theme.customProps.outlinedCardStyle.border,
        padding: theme.spacing(1),
        borderRadius: '5px',
        width: '50px',
        textAlign: 'center',
        color: '#3e4960',
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: theme.typography.fontFamily,
        cursor: 'pointer',
        transition: '0.3s',
        

        '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0.4) !important', 
            boxShadow: 'rgb(50 50 93 / 10%) 0px 2px 5px -1px, rgb(0 0 0 / 15%) 0px 1px 3px -1px',
          //transform: 'translateY(-3px)',
        },
        '&:hover $voteIcon': {
            //transform: 'translateY(-3px)',
        }
    },
    voteIcon: {
        //for $voteIcon
        fontSize: '45px',
        marginLeft: '-7px',
        marginTop: '-12px',
        marginBottom: '-10px',
        color: '#e5e5e5',
    },
    voteCountBoxUserHasVoted: {
        //borderColor: theme.palette.primary.light,
/*         overflow: 'hidden',
        position: 'relative',
        '&::before': {
            content: '" "',
            position: 'absolute',
            width: '3px',
            top: '0px',
            bottom: '0px',
            left: '0px',
            background: theme.palette.primary.main,
        } */
    },
    checkIcon: {
        color: theme.palette.primary.light,
        fontSize: '18px',
        fontWeight: '600',
        marginBottom: '5px',
    },
    commentCountSection: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    commentCount: {
        border: theme.customProps.outlinedCardStyle.border,
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        borderRadius: '5px',
        width: '50px',
        textAlign: 'center',
        color: theme.palette.text.secondary, //'#3e4960',
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: theme.typography.fontFamily,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    commentCountIcon:  {
        fontSize: '14px',
        marginRight: theme.spacing(0.5),
    },
    itemContentClickable: {
        cursor: 'pointer',
        '&:hover': {
            //backgroundColor: '#f7f7f8',
        },
    },
    sortBtn: {
        //$
    },
    toolBar: {
        [theme.breakpoints.down('sm')]: {
            //paddingLeft: theme.spacing(2),
            //paddingRight: theme.spacing(2),
            display: 'block',
            '&>*': {
                marginBottom: theme.spacing(1),
            },
            '& $sortBtn': {
                width: '100%',
            },
            '& $searchCont': {
                width: '100%',
            }
        },
    },
}))


export const FeedbackListView = ({items, noItemPadding, itemBottomBarRenderer, 
    loading, refreshing, 
    showScore, 
    showVoteCount,
    showUnclearFlag,
    onItemClick, 
    onVoteCreate,
    onVoteDelete,
    backlogEac,
    showAssets,
    showSortButton,
    listItemProps,
    variant,
    }) => {

    const classes = useStyles()

    const { t } = useTranslation(['vsprio', 'common'])

    const itemPaddingLeftRight = noItemPadding === true ? 0 : 3

    const [searchQuery, setSearchQuery] = useState('')

    const [sort, setSort] = useState('-createdAt');

    const filterItems = (items, query) => {
        if (!query) {
            return items;
        }

        return items.filter(i=>i.name?.toLowerCase().includes(query.toLowerCase()) || i.description?.toLowerCase().includes(query.toLowerCase()))
    }

    const itemsFiltered = useMemo(() => {
        if (!items) {
            return []
        }
        return filterItems(items, searchQuery).sort(dynamicSort(sort))
    }, [items, searchQuery, sort]);

    const isSearchAndNoItems = items && isHavingValue(searchQuery) && items?.length > 0 && itemsFiltered?.length === 0

    const renderItems = () => {
        if (isObjectEmpty(itemsFiltered)) {
            return (<>
                <Box pt={5} pb={5}>
                    <EmptyState
                        heading={t('common:data.emptyState.title')}
                    />
                </Box>
            </>)
        }

        const itemElems = itemsFiltered.map((i, idx)=> {
            return (
                <FeedbackListViewEntry 
                    key={idx}
                    item={i} 
                    showVoteCount={showVoteCount} 
                    showScore={showScore}
                    onClick={onItemClick}
                    onVoteCreate={onVoteCreate}
                    onVoteDelete={onVoteDelete}
                    itemBottomBarRenderer={itemBottomBarRenderer}
                    showAssets={showAssets}
                    backlogEac={backlogEac}
                    showUnclearFlag={showUnclearFlag}
                    {...listItemProps}
                    />
            )
        })

        return (<>
                {itemElems}
            </>)
    }

    const sortOptions = [
        {
            title: t('common:element.name'),
            key: 'name'
        },
        {
            title: t('common:comments.title'),
            key: 'commentCount'
        },
    ]

    if (showScore) {
        sortOptions.push(
            {
                title: t('vsprio:backlog.item.sortOption.ratingScore'),
                key: 'ratingScore'
            },
        )
    }

    if (showVoteCount) {
        sortOptions.push(
            {
                title: t('vsprio:portal.item.votes'),
                key: 'voteCount'
            },
        )
    }

    return (
        <Box className={classes.root}>
            <Box align="left" mb={variant === 'narrow' ? 2 : 5} display="flex" alignItems="center" className={classes.toolBar}>
                <Box flexGrow={1}>
                    <Box className={classes.searchCont}>
                        <SearchInput
                            value={searchQuery}
                            onChange={(newValue) => setSearchQuery(newValue)}
                            onCancelSearch={() => setSearchQuery('')}
                            cancelOnEscape={true}
                        />
                    </Box>
                </Box>
                <VerticalAlign>
                    {showSortButton !== false &&
                        <SortButton 
                                defaultOptions={['createdAt']}
                                options={sortOptions}
                                sort={sort}
                                onChange={(updated)=>setSort(updated)}
                                showText={false}
                                buttonProps={{
                                    size: 'small',
                                    className: classes.sortBtn
                                }}
                            />
                        }
                </VerticalAlign>
            </Box>
            <LoadingBorder loading={loading} variant="linear">
                {refreshing && (
                    <LinearProgress />
                )}
                {isSearchAndNoItems === true? 
                    <EmptyState heading="No search result" />
                    :
                    <Box className={classes.itemsWrapper}>
                        {renderItems()}
                    </Box>
                }
            </LoadingBorder>
        </Box>
    )

}

const FeedbackListViewEntry = ({item, showScore, onClick, itemBottomBarRenderer, showAssets, showVoteCount, 
    onVoteCreate, 
    onVoteDelete, 
    backlogEac, 
    showUnclearFlag,
    variant,
    showLabels,
    showCommentCount,
}) => {

    const classes = useStyles()

    const { t } = useTranslation(['vsprio', 'yoio'])

    const wallDialog = useWallDialog() 

    const [voteClicked, setVoteClicked] = useState(null)


    const isVotingAllowedForUser = backlogEac ? hasPermissionInEac(backlogEac, 'backlogItemVote') : false
    const hasPermissionEdit = backlogEac ? hasPermissionInEac(backlogEac, 'backlogEdit') : false

    const ratingScore = item.ratingScore || '-'

    const itemContentClassNamesArr = onClick ? [classes.itemContentClickable] : null

    const itemContentClassName = onClick ? itemContentClassNamesArr.join(' ') : null

    const assets = showAssets === true && item.assets?.length > 0 ?  assets : null

    const itemPadding = variant === 'narrow' ? 1 : 2

    const userVoted = isHavingValue(voteClicked) ? voteClicked : item.userContext?.voted //voteClicked has precedence

    const voteCountBoxClasses = [classes.voteCountBox]
    if (userVoted === true) {
        voteCountBoxClasses.push(classes.voteCountBoxUserHasVoted)
    }

    const voteCountCurrentChanged = isHavingValue(voteClicked) ? (voteClicked === true ? 1 : -1) : 0

    const voteCount = item.voteCount + voteCountCurrentChanged

    const handleItemClicked = () => {
        onClick(item)
    }

    const handleVoteClick = async () => {
        if (isVotingAllowedForUser !== true) {
            await wallDialog.getWallDialog({
                title: t('yoio:access.login.loginText'),
                message: t('vsprio:portal.voting.backlogItemVote.guestsDisabledNotice') 
                   + "\n" + t('vsprio:portal.permissionOnlyForPortalMembers.helperText'),
            })
            return;
        }

        if (userVoted === true) {
            if (voteClicked === true) {
                setVoteClicked(null)
            } else {
                setVoteClicked(false)
            }
            if (onVoteDelete) {
                onVoteDelete(item)
            }
        } else {
            if (voteClicked === false) {
                setVoteClicked(null)
            } else {
                setVoteClicked(true)
            }
            if (onVoteCreate) {
                onVoteCreate(item)
            }
        }
    }

    const rootClassNames = [classes.entryBoxWrapper]
    if (variant === 'narrow') {
        rootClassNames.push(classes.entryBoxWrapperNarrow)
    }

    return (
        <div className={joinClasses(rootClassNames)}>
            <Box display="flex" className={onClick ? classes.entryBoxWhenItemClickable : null}>
                <Box flexGrow={1} display="flex" onClick={onClick ? handleItemClicked : null} className={itemContentClassName} pt={itemPadding} pb={itemBottomBarRenderer ? 0 : itemPadding} minWidth="0px">
                    <Box flexGrow={1} pr={3} display="flex" flexDirection="column" justifyContent="center" minWidth="0px">
                        {variant === 'default' && 
                            <TypographyBreakText component="h4" className={classes.name}>{item.name}</TypographyBreakText>
                        }
                        {variant === 'narrow' && 
                            <>
                                {hasPermissionEdit ? 
                                    <ItemNameEditInline item={item}/> 
                                    :
                                    <TypographyEllipsis component="h4" className={classes.name} tooltipOnOverflowEnabled={true}>{item.name}</TypographyEllipsis>
                                }
                            </>
                        }
                        {item.description &&
                            <Box mt={1} color="text.secondary">
                                <TypographyBreakText><MultilineText className={classes.feedbackEntryDescripton}>{item.description}</MultilineText></TypographyBreakText>
                            </Box>
                         }
                        {variant === 'default' && showLabels !== false || showCommentCount !== false &&
                            <Box mt={2} display="flex" alignItems="center">
                                {showLabels !== false && item.labels?.type && (
                                    <Box mr={1}>
                                        <ItemLabelTypeChip type={item.labels?.type} />
                                    </Box>
                                )}
                                {showLabels !== false && item.labels?.status && item.labels.status === 'done' && (
                                    <Box mr={1}>
                                        <ItemLabelStatusChip type={item.labels.status} />
                                    </Box>
                                )}
                                {showCommentCount !== false && 
                                    <div className={classes.commentCount}><ChatBubbleOutlineIcon className={classes.commentCountIcon} />{item.commentCount}</div>
                                }
                            </Box>
                        }
                    </Box>
                    <Box className={classes.commentCountSection} pl={3}>
                        
                    </Box>
                </Box>

                    {(showVoteCount || showScore || showUnclearFlag) && (
                        <Box pl={3} pt={itemPadding} pb={itemPadding} textAlign="right" display="flex" alignItems={variant==='narrow'? 'center': null}>
                            {showVoteCount === true && (
                                <div>
                                    <Box className={joinClasses(voteCountBoxClasses)} mb={2} mr={1} onClick={handleVoteClick}>
                                        {userVoted === true ? 
                                            <CheckIcon className={classes.checkIcon} />
                                            :
                                            <ArrowDropUpIcon className={classes.voteIcon} />
                                        }
                                        <br/>
                                        {voteCount} 
                                    </Box>  
                                </div>
                            )}
                            {showScore === true && (
                                <div>
                                    <Tooltip title={t('vsprio:portal.item.ratingScore')}>
                                        <Box className={classes.itemScore} mb={2}>{ratingScore}</Box>  
                                    </Tooltip>
                                </div>
                            )}
                            {showUnclearFlag &&
                            <div>
                                <ItemUnclearVoteButton itemId={item.itemId} value={item.userContext?.unclearVoted}/>
                            </div> 
                            }
                        </Box>
                    )}

            </Box>

            {assets && (
                <Box mt={1}>
                    <AssetsView assets={assets} viewerAllowed={true}/>
                </Box>
            )}

            {itemBottomBarRenderer && (
                <Box pb={3}>{itemBottomBarRenderer(item)}</Box>
             )}
        </div>
    )

}

export const FeedbackItemCardContent = ({item, backlogId, backlogEac}) => {

    return (
        <>
            <Box pr={5}>{/** keep pr for close button */}
                <TypographyBreakText variant="h3">{item.name}</TypographyBreakText>
                {item.labels?.type && (
                    <Box mt={1}>
                        <ItemLabelTypeChip type={item.labels.type} />
                    </Box>
                )}
            </Box>

            <Box pb={3}>
                <Box mt={5} color="text.secondary">
                    <TypographyBreakText><MultilineText>{item.description || '-'}</MultilineText></TypographyBreakText>
                </Box>
                
                {item.assets && (
                    <Box mt={1}>
                        <AssetsView assets={item.assets} viewerAllowed={true}/>
                    </Box>
                )}

            </Box>

            <Divider />
            <Box pt={3}>
                <FeedbackCommentWidget 
                    itemId={item.itemId}
                    backlogId={backlogId}
                    backlogEac={backlogEac}
                />
            </Box>
        </>
    )

}