import { Fab } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { pushOneOrMany } from "utils/arrayUtils";
import { joinClasses } from "utils/muiUtils";
import { orElse } from "utils/objectUtils";

const useStyles = makeStyles((theme)=>({
    'elevation': {
        boxShadow: '0 7px 24px -10px #bbb',
        '$primary&': {
            boxShadow: '0 10px 20px -10px '+theme.palette.primary.main,
        },
    },
    primary: {
        //noop for $primary
    }
}))

const styles = {
    fab: theme=>({
        margin: 0,
        position: 'fixed',
        textTransform: 'none',
        fontSize: '14px',
        //borderRadius: '6px',
        fontFamily: theme.typography.fontFamily,
        paddingTop: '5px',
        paddingBottom: '5px',
        whiteSpace: 'nowrap',
        width: 'auto',
        height: 'auto',
    }),
    'variant-smooth': ()=>({
        boxShadow: 'rgb(45 54 70 / 4%) 0px 8px 12px, rgb(45 54 66 / 6%) 0px 2px 6px',
        border: '1px solid #f0f3fd',
        borderRadius: '50px !important',
        backgroundColor: '#fff',
    }),
    'position-bottom-center': {
        top: 'auto',
        left: '50%',
        bottom: 20,
        transform: 'translateX(-50%)',
    },
    'position-bottom-right': {
        top: 'auto',
        right: 20,
        bottom: 20,
    },
    'position-center-right': {
        bottom: '50%',
        transform: 'translateY(50%)',
        right: '0',
    },
}

export const FloatingButton = ({position, elevation, className, styleVariant, style, ...props}) => {

    const classes = useStyles()

    position = orElse(position, 'bottom-center')
    elevation = orElse(elevation, true)

    const sx = [styles.fab, styles['position-'+position]]

    if (styleVariant) {
        sx.push(styles['variant-'+styleVariant])
    }

    if (props.sx) {
        pushOneOrMany(sx, props.sx)
    }

    if (style) {
        pushOneOrMany(sx, style)
    }

    const classNames = []
    if (elevation) {
        classNames.push(classes.elevation)
    }
    if (props?.color === 'primary') {
        classNames.push(classes.primary)
    }
    if (className) {
        classNames.push(className)
    }

    return (
        <Fab className={joinClasses(classNames)} {...props} sx={sx}/>
    )

}