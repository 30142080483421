import { Typography, Box } from '@mui/material';

export const EmptyState = ({ heading, info }) => {
  return (
    <>
      <div>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h2">{heading}</Typography>
        </div>
        {info && (
          <div style={{ textAlign: 'center' }}>
          <Box mt={2} textAlign="center">
            <Typography>{info}</Typography>
          </Box>
        </div>
        )}
      </div>
    </>
  );
};
